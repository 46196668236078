import reactStringReplace from "react-string-replace";
import React from "react";
import { Link } from "gatsby";
import { useSiteMetadata } from "../../../hooks/defaults/Use-SiteMetadata";
import DrainageIncludes from "./Niches/Drainage/includes";
import DrainageExcludes from "./Niches/Drainage/excludes";
import RoofingIncludes from "./Niches/Roofing/includes";
import RoofingExcludes from "./Niches/Roofing/excludes";

function dynamicSort(property) {
	var sortOrder = 1;
	if (property[0] === "-") {
		sortOrder = -1;
		property = property.substr(1);
	}
	return function (a, b) {
		/* next line works with strings and numbers,
		 * and you may want to customize it to your needs
		 */
		var result =
			a[property].length > b[property].length
				? -1
				: a[property].length < b[property].length
				? 1
				: 0;
		return result * sortOrder;
	};
}

function preProcess({ text, excludes }) {
	let replacedText;
	excludes.map((link, id) => {
		if (id === 0) {
			replacedText = text.replaceAll(link.name, `XXXXX${id}XXXXXX`);
		} else {
			replacedText = replacedText.replaceAll(link.name, `XXXXX${id}XXXXXX`);
		}
		return null;
	});
	return replacedText;
}

function replaceWLinks({ text, linksPer, includes }) {
	includes.sort(dynamicSort("name"));
	let replacedText = text;
	let idx = 0;
	includes.map((link, id) => {
		idx = idx + 1;
		replacedText = reactStringReplace(replacedText, link.name, (match, i) => {
			idx = idx + 1;
			if (i <= linksPer) {
				return (
					<Link
						key={idx}
						to={link.link}
						className="underline hover:text-blue-800 duration-200"
					>
						{match}
					</Link>
				);
			} else {
				return match;
			}
		});
		return null;
	});
	return replacedText;
}

function postProcess({ replacedText, excludes }) {
	replacedText.map((line, line_id) => {
		var replaced = line;

		if (typeof line != "object") {
			excludes.map((link, link_id) => {
				replaced = replaced.replaceAll(`XXXXX${link_id}XXXXXX`, link.name);
				return null;
			});
			replacedText[line_id] = replaced;
		}
		return replacedText;
	});
	return replacedText;
}

const getLists = () => {
	const { mainService } = useSiteMetadata();
	switch (mainService) {
		case "Drainage":
			return {
				Includes: DrainageIncludes,
				Excludes: DrainageExcludes,
			};
		case "Roofing":
			return {
				Includes: RoofingIncludes,
				Excludes: RoofingExcludes,
			};
		default:
			return {
				Includes: [],
				Excludes: [],
			};
	}
};

export const InterLinking = ({ data, linksPer = 1 }) => {
	const { Includes, Excludes } = getLists();
	var text = data;
	if (text) {
		var excludes = Excludes.sort(dynamicSort("name"));
		var includes = Includes.sort(dynamicSort("name"));
		text = preProcess({ text, excludes });
		const replacedText = replaceWLinks({ text, linksPer, includes: includes });
		text = postProcess({ replacedText, excludes });

		return <>{replacedText}</>;
	} else {
		return <>{data}</>;
	}
};
