import * as React from "react";
import { Link, graphql } from "gatsby";
import RelatedQA from "../components/includes/relatedQA";
import Cta from "../components/CTA/CTAMain";
import Seo from "../components/seo";
import Layout from "../components/Defaults/Layout";

import { InterLinking } from "../components/utils/Interlinking/interLinking";

function titleCase(str) {
	var splitStr = str.toLowerCase().split(" ");
	for (var i = 0; i < splitStr.length; i++) {
		// You do not need to check if i is larger than splitStr length, as your for does that for you
		// Assign it back to the array
		splitStr[i] =
			splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
	}
	// Directly return the joined string
	return splitStr.join(" ");
}

const faqPage = ({ pageContext, data }) => {
	const { group, RelatedService, Search } = pageContext;

	return (
		<>
			<Layout>
				<Cta background={true} />
				<div className="max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-8 py-6 mt-6">
					<div className=" bg-blue-500/90  to-white shadow-xl p-4 rounded outline outline-[4px] hover:outline-[5px] outline-slate-500/20 hover:outline-blue-500/80 outline-offset-[6px] hover:outline-offset-0 duration-300">
						<div className="grid grid-cols-2 md:grid-cols-6   ">
							<div className="col-span-2 sm:col-span-6  flex my-auto ">
								<Link
									href="/faq"
									className="w-full text-center text-white rounded    "
								>
									All Our FAQ
								</Link>
							</div>
						</div>
					</div>
				</div>
				<div className="mt-6 mx-auto  max-w-screen-xl items-center mb-10 justify-between px-4">
					<h1 className="text-4xl text-blue-500 text-center my-20">
						{titleCase(Search)}
					</h1>
					<div className="bg-slate-800 via-blue-300 to-white shadow-xl p-10 rounded outline outline-[4px] hover:outline-[5px] outline-blue-500/20 hover:outline-blue-500/80 outline-offset-[6px] hover:outline-offset-0 duration-300">
						<h3>
							<span className="font-bold mb-2 text-white">
								Table of Content
							</span>
						</h3>
						{group.edges.map((edge, indx) => {
							return (
								<ul className="flex flex-col" key={indx}>
									<a
										className="
                   text-white text-base hover:text-blue-400"
										href={`#${edge.node.Heading}`}
									>
										{indx + 1} - {edge.node.Heading}
									</a>
								</ul>
							);
						})}
					</div>
				</div>
				{group.edges.map((edge, index) => {
					if (edge.node.Finished_Content) {
						var content2 = edge.node.Finished_Content;
						var content1 = content2.replaceAll(
							"COMPANYXXX",
							"A1 Roofing Surrey"
						);
						var content = content1.replaceAll("PHONEXXX", "01823 765044");
						var list = content.split("\n");
						if (list.length > 1) {
							return (
								<div
									id={edge.node.Heading}
									key={index}
									className="mt-6 mx-auto  max-w-screen-xl items-center mb-10 justify-between px-4"
								>
									<h2 className=" text-blue-500 text-2xl">
										{edge.node.Heading}
									</h2>
									<div className="mt-4 text-gray-700 flex flex-wrap">
										{list.map((item, indx) => {
											return (
												<p key={indx} className="flex mb-3">
													{item}
												</p>
											);
										})}
									</div>
								</div>
							);
						} else {
							return (
								<div
									key={index}
									id={edge.node.Heading}
									className="mt-6 mx-auto  max-w-screen-xl items-center mb-10 justify-between px-4"
								>
									<h2 className="text-blue-500  text-2xl">
										{edge.node.Heading}
									</h2>
									<p className="mt-4 text-gray-700">
										<InterLinking data={content} />
									</p>
								</div>
							);
						}
					} else {
						return null;
					}
				})}
				<RelatedQA RelatedService={RelatedService} data={data?.relatedQA} />
			</Layout>
		</>
	);
};
export const Head = ({ pageContext: { Search, group } }) => (
	<Seo
		title={titleCase(Search)}
		description={
			group.edges[1].node.Finished_Content
				? String(group.edges[1].node.Finished_Content).trim(150) + "..."
				: null
		}
	/>
);

export const query = graphql`
	query ($RelatedService: String) {
		relatedQA: allFaqsCsv(
			filter: { Related_Service: { eq: $RelatedService } }
		) {
			distinct(field: { Search: SELECT })
		}
	}
`;

export default faqPage;
