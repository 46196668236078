const Includes = [
	{
		name: "Drainage Services",
		link: "/drainage",
	},
	{
		name: "Drainage Company",
		link: "/drainage",
	},
	{
		name: "CCTV drain survey",
		link: "/cctv-drain-survey",
	},
];

export default Includes;
